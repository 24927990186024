export const institutions = [
  "Abubakar Tafawa Balewa University, Bauchi",
  "Ahmadu Bello University, Zaria",
  "Bayero University, Kano",
  "Federal University Gashua, Yobe",
  "Federal University of Petroleum Resources, Effurun",
  "Federal University of Technology, Akure",
  "Federal University of Technology, Minna",
  "Federal University of Technology, Owerri",
  "Federal University, Dutse, Jigawa State",
  "Federal University, Dutsin-Ma, Katsina",
  "Federal University, Kashere, Gombe State",
  "Federal University, Lafia, Nasarawa State",
  "Federal University, Lokoja, Kogi State",
  "Alex Ekwueme University, Ndufu-Alike, Ebonyi State",
  "Federal University, Otuoke, Bayelsa",
  "Federal University, Oye-Ekiti, Ekiti State",
  "Federal University, Wukari, Taraba State",
  "Federal University, Birnin Kebbi",
  "Federal University, Gusau Zamfara",
  "Michael Okpara University of Agricultural Umudike",
  "Modibbo Adama University of Technology, Yola",
  "National Open University of Nigeria, Lagos",
  "Nigeria Police Academy Wudil",
  "Nigerian Defence Academy Kaduna",
  "Nnamdi Azikiwe University, Awka",
  "Obafemi Awolowo University,Ile-Ife",
  "University of Abuja, Gwagwalada",
  "Federal University of Agriculture, Abeokuta",
  "University of Agriculture, Makurdi",
  "University of Benin",
  "University of Calabar",
  "University of Ibadan",
  "University of Ilorin",
  "University of Jos",
  "University of Lagos",
  "University of Maiduguri",
  "University of Nigeria, Nsukka",
  "University of Port-Harcourt",
  "University of Uyo",
  "Usumanu Danfodiyo University",
  "Nigerian Maritime University Okerenkoko, Delta State",
  "Air Force Institute of Technology, Kaduna",
  "Nigerian Army University Biu",
  "Abia State University, Uturu",
  "Adamawa State University Mubi",
  "Adekunle Ajasin University, Akungba",
  "Akwa Ibom State University, Ikot Akpaden",
  "Ambrose Alli University, Ekpoma",
  "Chukwuemeka Odumegwu Ojukwu University, Uli",
  "Bauchi State University, Gadau",
  "Benue State University, Makurdi",
  "Yobe State University, Damaturu",
  "Cross River State University of Technology, Calabar",
  "Delta State University Abraka",
  "Ebonyi State University, Abakaliki",
  "Ekiti State University",
  "Enugu State University of Science and Technology, Enugu",
  "Gombe State Univeristy, Gombe",
  "Ibrahim Badamasi Babangida University, Lapai",
  "Ignatius Ajuru University of Education,Rumuolumeni",
  "Imo State University, Owerri",
  "Sule Lamido University, Kafin Hausa, Jigawa",
  "Kaduna State University, Kaduna",
  "Kano University of Science & Technology, Wudil",
  "Kebbi State University of Science and Technology, Aliero",
  "Kogi State University Anyigba",
  "Kwara State University, Ilorin",
  "Ladoke Akintola University of Technology, Ogbomoso",
  "Ondo State University of Science and Technology Okitipupa",
  "River State University",
  "Olabisi Onabanjo University, Ago Iwoye",
  "Lagos State University, Ojo",
  "Niger Delta University Yenagoa",
  "Nasarawa State University Keffi",
  "Plateau State University Bokkos",
  "Tai Solarin University of Education Ijebu Ode",
  "Umar Musa Yar' Adua University Katsina",
  "Osun State University Osogbo",
  "Taraba State University, Jalingo",
  "Sokoto State University",
  "Yusuf Maitama Sule University Kano",
  "Oyo State Technical University Ibadan",
  "Ondo State University of Medical Sciences",
  "Edo University Iyamo",
  "Eastern Palm University Ogboko, Imo State",
  "University of Africa Toru Orua, Bayelsa State",
  "Bornu State University, Maiduguri",
  "Moshood Abiola University of Science and Technology Abeokuta",
  "Gombe State University of Science and Technology",
  "Zamfara State University",
  "Achievers University, Owo",
  "Adeleke University, Ede",
  "Afe Babalola University, Ado-Ekiti - Ekiti State",
  "African University of Science & Technology, Abuja",
  "Ajayi Crowther University, Ibadan",
  "Al-Hikmah University, Ilorin",
  "Al-Qalam University, Katsina",
  "American University of Nigeria, Yola",
  "Augustine University",
  "Babcock University,Ilishan-Remo",
  "Baze University",
  "Bells University of Technology, Otta",
  "Benson Idahosa University, Benin City",
  "Bingham University, New Karu",
  "Bowen University, Iwo",
  "Caleb University, Lagos",
  "Caritas University, Enugu",
  "Chrisland University",
  "Covenant University Ota",
  "Crawford University Igbesa",
  "Crescent University",
  "Edwin Clark University, Kaigbodo",
  "Elizade University, Ilara-Mokin",
  "Evangel University, Akaeze",
  "Fountain Unveristy, Oshogbo",
  "Godfrey Okoye University, Ugwuomu-Nike - Enugu State",
  "Gregory University, Uturu",
  "Hallmark University, Ijebi Itele, Ogun",
  "Hezekiah University, Umudi",
  "Igbinedion University Okada",
  "Joseph Ayo Babalola University, Ikeji-Arakeji",
  "Kings University, Ode Omu",
  "Kwararafa University, Wukari",
  "Landmark University, Omu-Aran.",
  "Lead City University, Ibadan",
  "Madonna University, Okija",
  "Mcpherson University, Seriki Sotayo, Ajebo",
  "Micheal & Cecilia Ibru University",
  "Mountain Top University",
  "Nile University of Nigeria, Abuja",
  "Novena University, Ogume",
  "Obong University, Obong Ntak",
  "Oduduwa University, Ipetumodu - Osun State",
  "Pan-Atlantic University, Lagos",
  "Paul University, Awka - Anambra State",
  "Redeemer's University, Ede",
  "Renaissance University, Enugu",
  "Rhema University, Obeama-Asa - Rivers State",
  "Ritman University, Ikot Ekpene, Akwa Ibom",
  "Salem University, Lokoja",
  "Samuel Adegboyega University, Ogwa.",
  "Southwestern University, Oku Owa",
  "Summit University",
  "Tansian University, Umunya",
  "University of Mkar, Mkar",
  "Veritas University, Abuja",
  "Wellspring University, Evbuobanosa - Edo State",
  "Wesley University. of Science & Technology, Ondo",
  "Western Delta University, Oghara Delta State",
  "Christopher University Mowe",
  "Kola Daisi University Ibadan, Oyo State",
  "Anchor University Ayobo Lagos State",
  "Dominican University Ibadan Oyo State",
  "Legacy University, Okija Anambra State",
  "Arthur Javis University Akpoyubo Cross river State",
  "Crown Hill University Eiyenkorin, Kwara State",
  "Coal City University Enugu State",
  "Clifford University Owerrinta Abia State",
  "Admiralty University, Ibusa Delta State",
  "Spiritan University, Nneochi Abia State",
  "Precious Cornerstone University, Oyo",
  "PAMO University of Medical Sciences, Portharcourt",
  "Atiba University Oyo",
  "Eko University of Medical and Health Sciences Ijanikin, Lagos",
  "Skyline University, Kano",
  "Akanu Ibiam Federal Polytechnic Unwana, P.M.B 1007, Afikpo, Ebonyi State",
  "Auchi Polytechnic, Auchi, Edo State",
  "Federal Polytechnic Ado Ekiti, Ekiti State",
  "Federal Polytechnic Bali, Taraba State",
  "Federal Polytechnic Bauchi, P.M. B 231, Bauchi State",
  "Federal Polytechnic Bida, P.M.B 55, Niger State",
  "Federal Polytechnic Damaturu, P.M.B 1006, Yobe State",
  "Federal Polytechnic Ede, Osun State",
  "Federal Polytechnic Ekowe, Bayelsa State",
  "Federal Polytechnic Idah, P.M.B 1035, Kogi State",
  "Federal Polytechnic Ilaro, P.M.B 50, Ogun State",
  "Federal Polytechnic Ile-Oluji, Ondo State",
  "Federal Polytechnic Kaura Namoda, P.M.B, 1012, Zamfara State",
  "Federal Polytechnic Mubi, PMB 35 Mubi, Adamawa State",
  "Federal Polytechnic Nasarawa, P.M.B. 01 Nasarawa State",
  "Federal Polytechnic Nekede, P.M.B 1036, Owerri, Imo State",
  "Federal Polytechnic Offa, P.M.B 420, Kwara State",
  "Federal Polytechnic Oko, Anambra State",
  "Federal Polytechnic of Oil and Gas Bonny, Rivers State",
  "Federal Polytechnic Ukana, Akwa Ibom State",
  "Hussaini Adamu Federal Polytechnic, P.M.B 5004, Kazaure Jigawa State",
  "Kaduna Polytechnic, Kaduna",
  "National Institute of Construction Technology Uromi",
  "Waziri Umaru Federal Polytechnic, P.M.B. 1034, Birnin Kebbi",
  "Yaba College of Technology, P.M.B. 2011, Yaba, Lagos State",
  "Airforce Institute of Technology (AFIT), NAF Base Kaduna",
  "Petroleum Training Institute Effurun",
  "Nigerian Army Institute of Technology and Environmental Science (NAITES) Makurdi",
  "Federal School of Dental Technology and Therapy, Enugu, Enugu State",
  "Abdu Gusau Polytechnic, Talata Mafara",
  "Abia State Polytechnic, Aba",
  "Abraham Adesanya Polytechnic, Dogbolu/Akanran Ibadan Road, Atikori, Ijebu Igbo",
  "Abubakar Tatari Ali Polytechnic, Bauchi",
  "Adamawa State Polytechnic, Yola",
  "Akwa Ibom State College of Art & Science, Nung Ukim",
  "Akwa Ibom State Polytechnic",
  "Bayelsa State College of Arts and Science, Elebele",
  "Benue State Polytechnic, Ugbokolo",
  "Binyaminu Usman Polytechnic, Hadejia",
  "Cross River State Institute of Technology And Management, Ugep",
  "D.S. Adegbenro ICT Polytechnic, Itori-Ewekoro, Ogun State",
  "Delta State Polytechnic, Ogwashi-Uku, Delta State",
  "Delta State Polytechnic, Otefe-Oghara, Delta State",
  "Delta State Polytechnic, Ozoro, Delta State",
  "Edo State Institute of Technology and Management, Usen, Edo State",
  "Gateway Polytechnic, Saapade Prof. Wole Soyinka Way, Saapade Estate, Ogun State",
  "Hassan Usman Katsina Polytechnic (HUK), Katsina State",
  "Ibarapa Polytechnic, Eruwa, Oyo State",
  "Imo State Polytechnic, Umuagwo, Ohaji, Imo State",
  "Institute of Management and Technology, Enugu, Enugu State",
  "Jigawa State Polytechnic, Dutse, Jigawa State",
  "Kano State Polytechnic, Kano, Kano State",
  "Ken Sarowiwa Polytechnic, Bori, Rivers State",
  "Kogi State Polytechnic, Lokoja, Kogi State",
  "Kwara State Polytechnic, Ilorin, Kwara State",
  "Lagos State Polytechnic, Ikorodu, Lagos State",
  "Mai-Idris Alooma Polytechnic, Geidam, Yobe State",
  "Nasarawa State Polytechnic, Nasarawa State",
  "Niger State Polytechnic, Zungeru, Niger State",
  "Nuhu Bamalli Polytechnic, Zaria, Kaduna State",
  "Ogun State Institute of Technology, Igbesa, Ogun State",
  "Ogun State Polytechnic, Ipokia, Ogun State",
  "Oke-Ogun Polytechnic, Shaki, Oyo State",
  "Osun State College of Technology, Esa-Oke, Osun State",
  "Osun State Polytechnic, Iree, Osun State",
  "Plateau State Polytechnic, Barkin-Ladi, Plateau State",
  "Port-Harcourt Polytechnic, Rivers State",
  "Ramat Polytechnic, Maiduguri, Borno State",
  "Rufus Giwa Polytechnic, Owo, Ondo State",
  "The Polytechnic Ibadan, Oyo State",
  "Umaru Ali Shinkafi Polytechnic, Sokoto, Sokoto State",
  "Zamfara State College of Arts and Science, Sokoto Road, Zamfara State",
  "Al-Hikma Polytechnic, Karu, Nasarawa State",
  "Allover Central Polytechnic, Sango Ota, Ogun State",
  "Ajayi Polytechnic, Ikere Ekiti, Ekiti State",
  "Ashi Polytechnic, Anyiin, Logo Local Government, Benue State",
  "Best Solution Polytechnic, Akure, Ondo State",
  "Bolmor Polytechnic, Ibadan, Oyo State",
  "Calvary Polytechnic, Owa-Oyibo, Delta State",
  "Citi Polytechnic, FCT Abuja",
  "College of Technology, Iresi, Osun State",
  "Covenant Polytechnic, Aba, Abia State",
  "Crown Polytechnic, Ado-Ekiti, Ekiti State",
  "Dorben Polytechnic, Bwari, Abuja",
  "Eastern Polytechnic, Rivers State",
  "Fidei Polytechnic, Gboko, Benue State",
  "Gboko Polytechnic, Gboko, Benue State",
  "Global Polytechnic, Akure, Ondo State",
  "Grace Polytechnic, Surulere, Lagos State",
  "Heritage Polytechnic, Ikot Udota, Akwa Ibom State",
  "Ibadan City Polytechnic, Oyo State",
  "Igbajo Polytechnic, Igbajo, Osun State",
  "Interlink Polytechnic, Osun State",
  "Kalac Christal Polytechnic, Lekki, Lagos State",
  "Kings Polytechnic, Ubiaja, Edo State",
  "Landmark Polytechnic, Ayetoro/Itele, Ogun State",
  "Lagos City Polytechnic, Ikeja, Lagos State",
  "Lens Polytechnic, Kwara State",
  "Lighthouse Polytechnic, Edo State",
  "Marist Polytechnic, Umuchigbo, Enugu State",
  "Mater Dei Polytechnic, Abia State",
  "Nacabs Polytechnic, Akwanga, Nasarawa State",
  "Nogak Polytechnic, Ikom, Cross River State",
  "Our Saviour Institute of Science, Agriculture & Technology, Enugu",
  "Prime Polytechnic, Jida Bassa, Ajaokuta, Kogi State",
  "Redeemers College of Technology and Management (RECTEM), Lagos State",
  "Ronik Polytechnic, Ejigbo, Lagos State",
  "Saf Polytechnic, Iseyin, Oyo State",
  "Savannah Institute of Technology, Ishieke Junction, Ebonyi State",
  "Shaka Polytechnic, Ogiso, Edo State",
  "St. Mary Polytechnic, Kwamba, Niger State",
  "Sure Foundation Polytechnic, Ikot Akai, Akwa Ibom State",
  "Temple Gate Polytechnic, Abayi, Abia State",
  "The Polytechnic, Igbo-Owu, Kwara State",
  "The Polytechnic, Ile Ife, Osun State",
  "The Polytechnic, Imesi-Ile, Osun State",
  "The Polytechnic, Otada Adoka, Benue State",
  "Tower Polytechnic, Ibadan, Oyo State",
  "Trinity Polytechnic, Uyo, Akwa Ibom State",
  "Uma Ukpai Polytechnic, Asaga Ohafia, Abia State",
  "Uyo City Polytechnic, Uyo, Akwa Ibom State",
  "Valley View Polytechnic, Ohafia, Abia State",
  "Wolex Polytechnic, Iwo, Osun State",
  "Federal College of Education (Technical), Asaba",
  "Federal College of Education, Kano",
  "Federal College of Education (Special), Oyo",
  "Federal College of Education, Abeokuta",
  "Federal College of Education, Eha-Amufu",
  "Federal College of Education (Technical), Gombe",
  "Federal College of Education, Kontagora",
  "Federal College of Education, Okene",
  "Federal College of Education (Technical), Omoku",
  "Federal College of Education (Tech), Potiskum",
  "Alvan Ikoku College of Education, Owerri",
  "Federal College of Education (Technical), Akoka",
  "Federal College of Education (Technical), Bichi",
  "Federal College of Education (Technical), Gusau",
  "Federal College of Education, Katsina",
  "Federal College of Education, Obudu",
  "Adeyemi College of Education, Ondo",
  "Federal College of Education, Pankshin",
  "Federal College of Education, Yola",
  "Federal College of Education, Zaria",
  "Nigerian Army School of Education (NASE), Ilorin",
  "Federal College of Education (T), Umunze",
  "College of Education, Gindiri",
  "Adamawa State College of Education, Hong",
  "Tai Solarin College of Education, Ijebu-Ode",
  "College of Education, Ikere-Ekiti",
  "Ebonyi State College of Education, Ikwo",
  "College of Education, Warri",
  "FCT College of Education, Zuba",
  "Osisatech College of Education, Enugu",
  "Nasarrawa State College of Education, Akwanga",
  "Isa Kaita College of Education, Dutsin-Ma",
  "College of Education, Ekiadolor-Benin",
  "College of Education, Gashua, Damaturu",
  "Kaduna State College of Education, Gidan-Waya, Kafanchan",
  "Osun State College of Education, Ilesa",
  "Kwara State College of Education, Ilorin",
  "College of Education, Katsina-Ala",
  "Sa'adatu Rimi College of Education, Kumbotso, Kano",
  "College of Education (Technical), Lafiagi",
  "Nwafor Orizu College of Education, Nsugbe",
  "Adeniran Ogunsanya College of Education, Otto/Ijanikin",
  "Emmanuel Alayande College of Education (EACOED), Oyo",
  "College of Education, Waka BIU",
  "St. Augustine College of Education (Project Time), Lagos",
  "Delta State College of Education, Agbor",
  "Akwa Ibom State College of Education, Afahansit",
  "Kogi State College of Education, Ankpa",
  "Adamu Augie College of Education, Argungu",
  "College of Education, Azare",
  "Umar Ibn Ibrahim El-Kanemi College of Education, Science and Technology, Bama",
  "College of Education, Jalingo",
  "Zamfara State College of Education, Maru",
  "Jigawa State College of Education, Gumel",
  "Niger State College of Education, Minna",
  "Rivers College of Education, Rumuolumeni",
  "Shehu Shagari College of Education, Sokoto",
  "Jama'Atu College of Education (JACE), Kaduna",
  "College of Education, Arochukwu, Abia",
  "College of Education, Ila-Orangun, Osun State",
  "Michael Otedola College of Prim. Education, Lagos",
  "Kashim Ibrahim College of Education, Maiduguri",
  "Delta State College of Physical Education, Mosogar",
  "Enugu State College of Education (T), Enugu",
  "Cross River State College of Education, Akampa",
  "Edo State College of Education, Igueben",
  "Isaac Jasper Boro College of Education, Sagbama",
  "Kogi State College of Education, Kabba",
  "Institute of Ecumenical Education (Thinkers Corner), Enugu",
  "Delar College of Education",
  "City College of Education, Mararaba, Gurku",
  "Ansar-Ud-Deen College of Education, Isolo",
  "Yewa Central College of Education, Ayetoro",
  "OSISA Tech College of Education, Enugu",
  "St. Augustine College of Education, Lagos",
  "African Thinkers Community of Inquiry, Enugu",
  "Muftau Olanihun College of Education, Ibadan",
  "Havard Wilson College of Education, Aba",
  "Muhyideen College of Education, Ilorin",
  "College of Education, Offa",
  "Bauchi Institute of Arabic & Islamic Studies, Bauchi",
  "Corner Stone College of Education, Lagos",
  "Peaceland College of Education, Enugu",
  "The College of Education, Nsukka",
  "Unity College of Education, Auka Adoka, Benue",
  "Diamond College of Education, Aba",
  "Kinsey College of Education, Ilorin, Kwara State",
  "ECWA College of Education, Jos (ECOEJ)"
];

export const specializations = [
  "Agriculture",
  "Agricultural Business",
  "Agricultural Business and Technology",
  "Agricultural Economics",
  "Farm and Ranch Management",
  "Agricultural Extension Services",
  "Agriculture Operations",
  "Agricultural Mechanics and Machinery",
  "Agricultural Production Operations",
  "Animal Sciences and Husbandry",
  "Animal Husbandry",
  "Animal Sciences",
  "Equine Studies",
  "Botany and Plant Sciences",
  "Aquaculture",
  "Botany and Plant Physiology",
  "Plant Science",
  "Crop and Soil Studies",
  "Agronomy, Range, and Crop Science",
  "Crop Production",
  "Soil Sciences",
  "Food Science and Technology",
  "Agricultural and Food Products Processing",
  "Food Science",
  "Horticultural",
  "Greenhouse, Nursery, and Floristry Operations",
  "Horticultural Sciences",
  "Horticulture Business Services",
  "Landscaping and Groundskeeping",
  "Turf and Turfgrass Management",
  "Architecture and Planning",
  "Architecture Studies",
  "Architectural Sciences and Technology",
  "Architecture",
  "Environmental Architecture and Design",
  "Interior Architecture",
  "Landscape Architecture",
  "Historic Preservation and Conservation",
  "Planning and Development",
  "Land Use Planning and Development",
  "Real Estate Development",
  "Urban, Community and Regional Planning",
  "Arts",
  "Art History",
  "Art Studies",
  "Arts, Entertainment, and Media Management",
  "Fine and Studio Arts Management",
  "Music Management",
  "Theatre, Arts, and Media Management",
  "Design and Applied Arts",
  "Commercial and Advertising Art",
  "Commercial Photography",
  "Design and Visual Communications",
  "Fashion and Apparel Design",
  "Game and Interactive Media Design",
  "Graphic Design",
  "Illustration",
  "Industrial and Product Design",
  "Interior Design",
  "Film, Video, and Photography",
  "Cinematography and Video Production",
  "Film and Video Studies",
  "Photography",
  "Fine Arts",
  "Ceramics",
  "Crafts and Artisanry",
  "Drawing",
  "Fiber, Textile and Weaving",
  "Metal and Jewelry Arts",
  "Multimedia",
  "Painting",
  "Printmaking",
  "Sculpture",
  "Studio Arts",
  "Music",
  "Conducting",
  "Music History and Literature",
  "Music Performance",
  "Music Technology",
  "Music Theory and Composition",
  "Musical Instruments",
  "Voice and Opera",
  "Performing Arts",
  "Acting",
  "Dance",
  "Drama and Theatre Production",
  "Theatre Design",
  "Visual Arts",
  "Biological Sciences",
  "Anatomy",
  "Animal Biology",
  "Animal Physiology and Behavior",
  "Zoology and Entomology",
  "Biochemistry and Molecular Biology",
  "Bioinformatics",
  "Biology",
  "Biological and Physical Sciences",
  "Biology and Biological Sciences",
  "Biology Laboratory Technician",
  "Biomedical Sciences and Molecular Medicine",
  "Biotechnology",
  "Cellular Biology",
  "Ecology, Evolution, and Population Biology",
  "Ecology and Evolutionary Biology",
  "Epidemiology",
  "Marine Biology and Oceanography",
  "Population Biology",
  "Genetics",
  "Microbiology",
  "Molecular Biology",
  "Neuroscience and Neurobiology",
  "Neuroanatomy",
  "Neuroscience",
  "Pharmacology and Toxicology",
  "Pharmacology",
  "Toxicology",
  "Physiology and Pathology",
  "Exercise Physiology",
  "Pathology",
  "Physiology",
  "Business",
  "Business Management",
  "Business Administration and Management",
  "Construction Management",
  "Corporate Communications",
  "E-Commerce",
  "International Business",
  "Logistics and Supply Chain Management",
  "Managerial Economics",
  "Non-Profit and Public Management",
  "Office Management",
  "Operations Management",
  "Procurement and Contracts Management",
  "Business Support Services",
  "Administrative Assistant and Secretarial Science",
  "Clerical Services",
  "Customer Service Support and Call Center Operation",
  "Executive Assistant",
  "Entrepreneurship and Small Business Operations",
  "Entrepreneurship",
  "Franchise Operations",
  "Small Business Management",
  "Finance and Accounting",
  "Accounting",
  "Accounting Technician and Bookkeeping",
  "Actuarial Science",
  "Banking and Finance",
  "Finance",
  "Financial Planning",
  "Financial Services Marketing",
  "Insurance",
  "Investments and Securities",
  "Taxation",
  "Hospitality and Tourism Management",
  "Hospitality Management",
  "Hotel and Resort Management",
  "Restaurant and Food Services Management",
  "Tourism and Travel Services Management",
  "Human Resources",
  "Human Resources Development",
  "Human Resources Management",
  "Labor and Industrial Relations",
  "Organizational Behavior Studies",
  "Management Sciences and Information Systems",
  "Management Information Systems",
  "Management Sciences and Statistics",
  "Sales and Marketing",
  "Fashion and Apparel Merchandising",
  "Marketing",
  "Merchandising and Buying Operations",
  "Real Estate",
  "Retailing",
  "Selling and Sales",
  "Communications",
  "Advertising",
  "Communication and Media Studies",
  "Communications Technicians",
  "Digital and Print Journalism",
  "Broadcast Journalism",
  "Journalism",
  "Photojournalism",
  "Digital and Print Publishing",
  "Desktop Publishing and Digital Imaging Design",
  "Publishing",
  "Graphic Communications",
  "Animation, Video Graphics and Special Effects",
  "Graphic and Printing Equipment Operation",
  "Graphic Communication and Printing",
  "Mass Communications and Media",
  "Mass Communication",
  "Public Relations",
  "Radio and Television Broadcasting Technician",
  "Radio, Television, and Digital Communication",
  "Recording Arts Technician",
  "Organizational Communication",
  "Speech Communication and Rhetoric",
  "Computer and Information Sciences",
  "Computer and IT Administration",
  "Computer and Information Systems Security",
  "IT Services Administration",
  "Multimedia Management and Webmaster",
  "Network, Database, and System Administration",
  "System, Networking, and LAN/WAN Management",
  "Computer Programming",
  "Computer Science",
  "Computer Systems",
  "Computer Systems Analysis",
  "Computer Systems Networking and Telecommunications",
  "Data Processing",
  "Data Entry",
  "Data Processing Technician",
  "Digital Design",
  "Computer Graphics",
  "Web Page and Digital Design",
  "Information Studies",
  "Artificial Intelligence",
  "Computer and Information Studies",
  "Information Technology",
  "Education",
  "Education Studies and Research",
  "Bilingual and Multicultural Education",
  "Curriculum",
  "Educational Media Design",
  "Educational Research",
  "Foundations of Education",
  "Educational Administration and Supervision",
  "Educational Administration",
  "Educational, Instructional, and Curriculum Supervision",
  "Elementary and Middle School Administration",
  "High School Administration",
  "Higher Education Administration",
  "Superintendence and Educational System Administration",
  "Library Science",
  "Library and Archives Assisting",
  "Library Science and Administration",
  "School Librarian",
  "School Counseling",
  "College Student Counseling and Personnel Services",
  "School Counseling and Guidance Services",
  "Special Education",
  "Education of Individuals with Impairments and Disabilities",
  "Education of the Gifted and Talented",
  "Special Education and Teaching",
  "Teaching",
  "Adult and Continuing Education",
  "Agricultural Teacher Education",
  "Art Teacher Education",
  "Business Teacher Education",
  "Computer Teacher Education",
  "Early Childhood Education",
  "Elementary Education",
  "English and Speech Teacher Education",
  "Foreign Language Teacher Education",
  "Health Teacher Education",
  "High School Education",
  "History Teacher Education",
  "Kindergarten and Preschool Education",
  "Mathematics Teacher Education",
  "Middle School Education",
  "Music Teacher Education",
  "Physical Education Teaching and Coaching",
  "Reading Teacher Education",
  "Science Teacher Education",
  "Social Science Teacher Education",
  "Social Studies Teacher Education",
  "Teacher Education for Multiple Levels",
  "Teaching English as a Second Language",
  "Technical Teacher Education",
  "Technology Teacher Education",
  "Trade and Industrial Teacher Education",
  "Teaching Assistant",
  "Engineering",
  "Aerospace Engineering",
  "Aerospace and Aeronautical Engineering",
  "Aerospace Engineering Technician",
  "Agricultural and Environmental Engineering",
  "Agricultural Engineering",
  "Environmental Engineering",
  "Environmental Engineering Technician",
  "Water, Wastewater, and Recycling Technician",
  "Architectural Engineering and Technology",
  "Architectural Engineering",
  "Architectural Engineering Technician",
  "Biological Engineering",
  "Bioengineering and Biomedical Engineering",
  "Biomedical Technician",
  "Chemical Engineering",
  "Civil and Surveying Engineering",
  "Civil Engineering",
  "Civil Engineering Technician",
  "Surveying Engineering",
  "Surveying Technician",
  "Computer Engineering",
  "Computer Engineering Technician",
  "Computer Hardware Engineering",
  "Computer Software Engineering",
  "Computer Systems Technician",
  "Construction Engineering and Technology",
  "Construction Engineering",
  "Construction Engineering Technician",
  "Welding Engineering Technician",
  "Electrical and Communications Engineering",
  "Electrical and Electronics Engineering",
  "Electrical Engineering Technician",
  "Telecommunications Technician",
  "Engineering Drafting and Design",
  "Architectural Drafting (CAD/CADD)",
  "Drafting and Design Engineering Technician (CAD/CADD)",
  "Engineering Design",
  "Mechanical Drafting (CAD/CADD)",
  "Engineering Sciences and Mechanics",
  "Engineering Mechanics",
  "Engineering Physics",
  "Engineering Science",
  "Engineering Studies",
  "Industrial Engineering and Technology",
  "Industrial and Engineering Management",
  "Industrial Engineering",
  "Industrial Production Technician",
  "Industrial Technician",
  "Manufacturing Engineering and Technology",
  "Hazardous Materials and Industrial Safety Technician",
  "Manufacturing Engineering",
  "Manufacturing Engineering Technician",
  "Occupational Safety and Health Technician",
  "Quality Control Technician",
  "Mechanical Engineering and Technology",
  "Automotive Engineering Technician",
  "HVAC and Refrigeration Engineering Technician",
  "Instrumentation Technician",
  "Mechanical Engineering",
  "Mechanical Engineering Technician",
  "Robotics and Automation Engineering",
  "Robotics and Automation Engineering Technician",
  "Mineral and Petroleum Engineering",
  "Mining and Mineral Engineering",
  "Mining and Petroleum Technician",
  "Petroleum Engineering",
  "Nuclear Engineering and Technology",
  "Nuclear Engineering",
  "Nuclear Engineering Technician",
  "Operations Research",
  "Plastics and Materials Engineering",
  "Materials Engineering",
  "Textile and Plastics Engineering",
  "Systems Engineering",
  "Environmental Sciences",
  "Conservation and Environmental Studies",
  "Environmental and Marine Sciences",
  "Environmental Studies",
  "Natural Resources Conservation",
  "Sustainability Studies",
  "Forest Sciences",
  "Forest and Wood Sciences",
  "Forestry",
  "Natural Resource Management",
  "Forest Resources Management",
  "Natural Resource Recreation and Tourism",
  "Natural Resources Management and Policy",
  "Wildlife and Fisheries Management",
  "Health Care",
  "Alternative Medicine",
  "Acupuncture and Oriental Medicine",
  "Alternative Medical Systems and Holistic Health",
  "Midwifery",
  "Traditional Chinese Medicine",
  "Chiropractic Studies",
  "Communication Disorders",
  "Audiology",
  "Communication Sciences and Disorders",
  "Speech Language Pathology",
  "Speech Language Pathology Assistant",
  "Dental Studies",
  "Advanced Dentistry and Oral Sciences",
  "Dental Assisting",
  "Dental Hygiene",
  "Dentistry",
  "Diagnostics",
  "Cardiovascular Technician",
  "Clinical Laboratory Technician",
  "Diagnostic Technician",
  "Medical Laboratory Technician",
  "Nuclear and Industrial Radiologic Technology",
  "Phlebotomy Technician",
  "Radiologic Technician",
  "Sonographer and Ultrasound Technician",
  "Dietetics and Clinical Nutrition",
  "Emergency Care Attendant (EMT)",
  "Emergency Medical Technician (EMT Paramedic)",
  "Health and Medical Administrative Services",
  "Health Care Management",
  "Health Unit Coordinator and Manager",
  "Hospital Management",
  "Medical Insurance Billing and Claims",
  "Medical Insurance Coding",
  "Medical Management and Clinical Assistant",
  "Medical Office Assistant",
  "Medical Office Computer Specialist",
  "Medical Office Management",
  "Medical Reception",
  "Medical Records Administration",
  "Medical Records Technician",
  "Medical Secretary",
  "Medical Transcription",
  "Intervention and Treatment",
  "Athletic Training",
  "Intervention and Treatment Professions",
  "Nuclear Medical Technician",
  "Radiation Therapy",
  "Respiratory Care Therapy",
  "Surgical Technologist",
  "Medical Assisting",
  "Anesthesiologist Assistant",
  "Clinical Laboratory Assistant",
  "Medical Assistant",
  "Radiologist Assistant",
  "Respiratory Therapy Technician",
  "Medicine and Medical Studies",
  "Health Service Preparatory Studies",
  "Medicine",
  "Osteopathy",
  "Podiatry",
  "Pre-Medicine Studies",
  "Mental and Social Health Services",
  "Community Health Services and Counseling",
  "Marriage and Family Therapy and Counseling",
  "Medical Social Work",
  "Mental and Social Health Studies",
  "Mental Health Counseling",
  "Psychiatric and Mental Health Services",
  "Substance Abuse and Addiction Counseling",
  "Nursing",
  "Adult Health Nursing",
  "Family Practice and Pediatric Nursing",
  "Licensed Practical Nurse Training (LPN)",
  "Nurse Anesthetist",
  "Nursing Administration",
  "Nursing Assistant",
  "Nursing Science, Education, and Practice",
  "Registered Nursing (RN)",
  "Optometry",
  "Pharmacy and Pharmaceutical Sciences",
  "Pharmaceutical Sciences",
  "Pharmacy Studies",
  "Pharmacy Technician",
  "Physician Assistant",
  "Public Health",
  "Community Health and Preventive Medicine",
  "Environmental Health",
  "Health Services Administration",
  "Occupational Health and Industrial Hygiene",
  "Public Health Education",
  "Public Health Studies",
  "Rehabilitation and Therapy",
  "Alternative Therapeutic Professions",
  "Aromatherapy, Herbalism, and Reiki",
  "Health Aides and Attendants",
  "Massage Therapy and Bodywork",
  "Occupational Therapist Assistant",
  "Occupational Therapy",
  "Physical Therapy",
  "Physical Therapy Technician",
  "Therapeutic Recreation",
  "Vocational Rehabilitation Counseling",
  "Yoga and Movement Therapy",
  "Veterinary Studies",
  "Veterinary Medicine",
  "Veterinary Sciences",
  "Veterinary Technician and Assistant",
  "Languages and Literature",
  "English",
  "Creative Writing",
  "English Language Studies",
  "Literature",
  "Professional, Technical, Business, and Scientific Writing",
  "Rhetoric and Composition",
  "Language Studies and Linguistics",
  "Comparative Literature",
  "Foreign Languages and Literatures",
  "Linguistics, Interpretation, and Translation",
  "Regional Language and Literature Studies",
  "Arabic Language and Literature",
  "Chinese Language and Literature",
  "East Asian Languages and Literatures",
  "German Language and Literature",
  "Hebrew Language and Literature",
  "Japanese Language and Literature",
  "Latin and Ancient Greek Studies",
  "Russian and Eastern European Languages and Literature",
  "South and Southeast Asian Languages and Literatures",
  "Romance Languages",
  "French Language and Literature",
  "Italian Language and Literature",
  "Romance Languages and Literatures",
  "Spanish Language and Literature",
  "Sign Language",
  "American Sign Language (ASL)",
  "Sign Language Interpretation and Translation",
  "Law",
  "Advanced Legal Studies",
  "Advanced Legal Research",
  "Banking, Corporate, and Tax Law",
  "Energy, Environment, and Natural Resources Law",
  "Health Law",
  "Intellectual Property Law",
  "International Legal Studies",
  "Programs for Foreign Lawyers",
  "US Law",
  "Court Reporting",
  "Law and Legal Studies",
  "Law Studies",
  "Legal Studies",
  "Pre-Law Studies",
  "Legal Administrative Assistant",
  "Paralegal",
  "Mathematics & Statistics",
  "Computational and Applied Mathematics",
  "Mathematics",
  "Statistics",
  "Mechanics and Repair",
  "Electronics Maintenance and Repair",
  "Communications Systems Installation and Repair",
  "Computer Installation and Repair",
  "Electronics Equipment Installation and Repair",
  "Industrial Electronics Technician",
  "Equipment Maintenance",
  "Heavy Equipment Maintenance Technician",
  "Industrial Mechanics and Maintenance Technician",
  "General Mechanics and Repair",
  "HVAC Technician",
  "Precision Systems Maintenance and Repair",
  "Vehicle Maintenance and Repair Technologies",
  "Aircraft Maintenance",
  "Aircraft Powerplant Technician",
  "Autobody and Collision Repair",
  "Automotive Mechanics",
  "Avionics Maintenance Technician",
  "Bicycle Mechanics and Repair",
  "Diesel Mechanics",
  "Motorcycle Maintenance and Repair",
  "Small Engine Mechanics and Repair",
  "Vehicle Maintenance and Repair",
  "Military Science",
  "Intelligence and Information",
  "Information Operations",
  "Intelligence",
  "Military Applied Sciences",
  "Combat Systems Engineering and Stealth Technology",
  "Space and Undersea Systems Operations",
  "Military Systems Technology",
  "Air, Space, and Radar Technology",
  "Military Information Systems and Missile Technology",
  "Military Technologies",
  "Philosophy and Religion",
  "Philosophy Studies",
  "Ethics",
  "Logic",
  "Philosophy",
  "Religion",
  "Buddhism",
  "Christianity",
  "Hinduism",
  "Islam",
  "Judaism",
  "Religious Studies",
  "Theology and Religious Vocations",
  "Biblical Studies",
  "Divinity, Ministry, and Pre-Theology",
  "Missionary Studies",
  "Pastoral Counseling and Specialized Ministries",
  "Religious Education",
  "Talmudic and Rabbinical Studies",
  "Theological and Ministerial Studies",
  "Physical Sciences",
  "Astronomy and Astrophysics",
  "Astronomy",
  "Astrophysics",
  "Atmospheric Sciences and Meteorology",
  "Atmospheric Sciences",
  "Climatology",
  "Meteorology",
  "Chemistry and Chemical Technology",
  "Chemical Technician",
  "Chemistry",
  "Earth Sciences",
  "Geology and Earth Science",
  "Natural Sciences",
  "Materials Science",
  "Optics",
  "Physical Science Studies",
  "Physics",
  "Systems Science and Theory",
  "Protective Services",
  "Criminal Justice and Corrections",
  "Corrections and Corrections Administration",
  "Criminal Justice and Law Enforcement Administration",
  "Criminal Justice and Safety Studies",
  "Fire Protection",
  "Fire Prevention and Safety Technology",
  "Fire Science and Fire Fighting",
  "Fire Services Administration",
  "Forensic Science and Technology",
  "Cyber/Computer Forensics and Counterterrorism",
  "Homeland Security",
  "Police and Criminal Science",
  "Security and Loss Prevention Services",
  "Psychology & Counseling",
  "Clinical Psychology and Counseling",
  "Clinical Psychology",
  "Community Psychology",
  "Counseling Psychology",
  "Forensic Psychology",
  "Industrial and Organizational Psychology",
  "School Psychology",
  "Psychology",
  "Psychology Studies",
  "Developmental and Child Psychology",
  "Physiological Psychology",
  "Research and Experimental Psychology",
  "Social Psychology",
  "Recreation & Fitness",
  "Health and Physical Fitness Education",
  "Kinesiology and Exercise Science",
  "Parks and Recreation",
  "Parks, Recreation and Leisure Facilities Management",
  "Parks, Recreation and Leisure Studies",
  "Sport and Fitness Management",
  "Services",
  "Animal Services",
  "Animal Grooming",
  "Animal Training",
  "Taxidermy",
  "Child Care Services",
  "Child Care Management",
  "Child Care Provider",
  "Cosmetology and Personal Grooming Services",
  "Barbering",
  "Cosmetology",
  "Cosmetology Instruction and Salon Management",
  "Esthetician and Skin Care",
  "Facial Treatment",
  "Hair Styling and Design",
  "Make-Up Artist and Tattooing",
  "Nail Technician",
  "Personal Services",
  "Culinary Arts and Food Service",
  "Baking and Pastry Arts",
  "Bartending",
  "Cooking and Culinary Science",
  "Culinary Arts",
  "Food Preparation and Service",
  "Restaurant, Culinary, and Catering Management",
  "Funeral Service and Mortuary Science",
  "Funeral Service and Direction",
  "Mortuary Science and Embalming",
  "Skilled Trades and Construction",
  "Boilermaker",
  "Carpentry and Construction",
  "Carpentry",
  "General Construction Trades",
  "Woodworking",
  "Concrete and Masonry",
  "Concrete Finishing",
  "Masonry",
  "Construction Finishing and Inspection",
  "Building Construction Inspection",
  "Building Construction Technology",
  "Carpet, Floor, and Tile Worker",
  "Drywall Installation",
  "Glazier",
  "Industrial Painting",
  "Insulator",
  "Metal Building Assembly",
  "Property Maintenance and Site Management",
  "Roofer",
  "Electrical Trades",
  "Electrical and Power Transmission Installation",
  "Electrician",
  "Lineworker",
  "Leatherworking and Upholstery",
  "Machine and Metal Working",
  "Machine Shop Assistant",
  "Machinist",
  "Sheet Metal Technology",
  "Tool and Die Technician",
  "Plumbing Trades",
  "Pipefitting",
  "Plumbing",
  "Precision Production",
  "Welding",
  "Social Sciences & Liberal Arts",
  "Area and Ethnic Studies",
  "African American Studies",
  "African Studies",
  "Asian Studies",
  "European and Russian Studies",
  "Hispanic American Studies",
  "Latin American Studies",
  "Minority and Group Studies",
  "North American Studies",
  "Regional and Area Studies",
  "Women's Studies",
  "Consumer and Retail Merchandising",
  "Apparel and Textile Science",
  "Retailing Management",
  "Economics",
  "Economic Development",
  "Economics and Econometrics",
  "Family and Consumer Economics",
  "Family Studies and Consumer Sciences",
  "Food and Nutrition Studies",
  "Food Service Systems Management",
  "Foods, Nutrition, and Wellness Studies",
  "Nutrition Sciences",
  "History",
  "Human Development",
  "Adult Development and Aging",
  "Behavioral Sciences",
  "Child Development",
  "Cognitive Science",
  "Family and Community Services",
  "International Studies and Government",
  "International Relations and National Security Studies",
  "International Studies",
  "Peace Studies and Conflict Resolution",
  "Political Science and Government",
  "Liberal Arts and Humanities",
  "Social Sciences",
  "Anthropology and Archaeology",
  "Criminology",
  "Geography",
  "Gerontology",
  "Social Science Research Methods",
  "Sociology",
  "Urban Studies and Affairs",
  "Social Services",
  "Community Organization and Advocacy",
  "Human Services",
  "Public Policy, Administration, and Social Work",
  "Public Administration",
  "Public Policy Analysis",
  "Social Work and Youth Services",
  "Transportation",
  "Air Transportation",
  "Aeronautics and Aviation Technology",
  "Air Traffic Control",
  "Airline Flight Attendant",
  "Aviation Management and Operations",
  "Flight Instructor",
  "Professional Pilot",
  "Ground Transportation",
  "Construction and Heavy Equipment Operation",
  "Truck, Bus, and Commercial Vehicle Operation",
  "Marine Transportation",
  "Commercial Fishing",
  "Marine Science",
  "Professional Diver and Instructor",
  "Transportation and Materials Moving",
  "Railroad and Railway Transportation",
  "Yoruba",
  "Hausa",
  "Undecided",
  "Other"
];

export const subjectsTaught = [
  "English Language",
  "Mathematics",
  "Yoruba",
  "Civic Education",
  "Animal Husbandry (Trade Subject)",
  "Data Processing (Trade Subject)",
  "Store-Keeping (Trade Subject)",
  "Marketing (Trade Subject)",
  "Physics",
  "Literature",
  "Financial Accounting",
  "Chemistry",
  "Government",
  "Biology",
  "Commerce",
  "Christian Religious Studies",
  "Further Mathematics",
  "Geography",
  "Economics",
  "Computer Studies",
  "Technical Drawing",
  "Agricultural Science",
  "Social Studies",
  "Civic Education",
  "Security Studies",
  "History",
  "Christian Religious Studies",
  "Islamic Religious Studies",
  "Physical and Health Education",
  "French",
  "Hausa (Language of Immediate Environment)",
  "Business Studies",
  "Home Economics",
  "Agricultural Science",
  "Fine Art",
  "Computer Studies",
  "Basic Science",
  "Basic Technology",
  "Music",
  "Vocational Studies"
];

export const states = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
  "Federal Capital Territory (FCT)",
  "Other"
];

export const yewaDivisionZones = [
  "Ado-Odo Ota",
  "Yewa North",
  "Imeko Afon",
  "Yewa South",
  "Ipokia",

];

export const ijebuDivisionZones = [
  "Ogun Water Side",
  "Ijebu East",
  "Ijebu North East",
  "Ijebu Ode",
  "Odogbolu",
  "Ijebu North",
]

export const zones = [
  "Abeokuta North",
  "Abeokuta South",
  "Obafemi-Owode",
  "Ifo",
  "Odeda",
  "Ewekoro",
  "Remo North",
  "Ikenne",
  "Sagamu",
  "Ogun Water Side",
  "Ijebu East",
  "Ijebu North East",
  "Ijebu Ode",
  "Odogbolu",
  "Ijebu North",
  "Ado-Odo Ota",
  "Yewa North",
  "Imeko Afon",
  "Yewa South",
  "Ipokia",
 
]

export const remoDivisionZones = [
"Remo North",
"Ikenne",
"Sagamu"
]

export const egbaDivisionZones = [
  "Abeokuta North",
  "Abeokuta South",
  "Obafemi-Owode",
  "Ifo",
  "Odeda",
  "Ewekoro",
 
]

export const PFA = [
  "Not Available Yet",
  "AIICO Pension Managers Limited",
  "APT Pension Fund Managers Limited",
  "ARM Pension Managers (PFA) Limited",
  "CrusaderSterling Pensions Limited",
  "Fidelity Pension Managers Limited",
  "First Guarantee Pension Limited",
  "Future Unity Glanvills Pensions Limited",
  "IEI-Anchor Pension Managers Limited",
  "Investment One Pension Managers Limited",
  "Leadway Pensure PFA Limited",
  "NLPC Pension Fund Administrators Limited",
  "Nigerian University Pension Management Company (NUPEMCO)",
  "NPF Pensions Limited",
  "Oak Pensions Limited",
  "Pensions Alliance Limited (PAL Pensions)",
  "Premium Pension Limited",
  "Radix Pension Managers Limited",
  "Sigma Pensions Limited",
  "Stanbic IBTC Pension Managers Limited",
  "Trustfund Pensions Limited",
  "Veritas Glanvills Pensions Limited"
];

export const Years = [
  "1966",
  "1967",
  "1968",
  "1969",
  "1970",
  "1971",
  "1972",
  "1973",
  "1974",
  "1975",
  "1976",
  "1977",
  "1978",
  "1979",
  "1980",
  "1981",
  "1982",
  "1983",
  "1984",
  "1985",
  "1986",
  "1987",
  "1988",
  "1989",
  "1990",
  "1991",
  "1992",
  "1993",
  "1994",
  "1995",
  "1996",
  "1997",
  "1998",
  "1999",
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027"
];

export const professionalGradeLevel = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17"
];
export const nonProfessionalGradeLevel = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14"
];

export const secretariatAssistantGradeLevel = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9"
]

export const assistantChiefSecretariatGradeLevel = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10"
]

export const chiefSecretariatGradeLevel = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "12"
]

export const driverStorekeeperClericalGradeLevel = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
]

export const messengerWatchmanGradeLevel = [
  "1",
  "2",
  "3",
  "4",
]

export const cleanerGradeLevel = [
  "1",
  "2",
  "3",
]

export const teachingOrNonTeaching = ["TEACHING", "NON-TEACHING"];


export const wards =  ["Ward 1", "Ward 2", "Ward 3", "Ward 4", "Ward 5", "Ward 6", "Ward 7", "Ward 8", "Ward 9", "Ward 10", "Ward 11", "Ward 12", "Ward 13", "Ward 14", "Ward 15", "Ward 16", "Ward 17", "Ward 18", "Ward 19", "Ward 20", "Ward 21", "Ward 22", "Ward 23", "Ward 24", "Ward 25", "Ward 26", "Ward 27", "Ward 28", "Ward 29", "Ward 30"]

export const localGovernmentOfOrigin = [
  "EWEKORO",
  "ABEOKUTA SOUTH",
  "ODOGBOLU",
  "SAGAMU",
  "ADO ODO OTA",
  "ABEOKUTA NORTH",
  "IJEBU-ODE",
  "OBAFEMI-OWODE",
  "IJEBU-NORTH",
  "YEWA-NORTH",
  "IMEKO-AFON",
  "YEWA-SOUTH",
  "IPOKIA",
  "IJEBU NORTH-EAST",
  "IFO",
  "ODEDA",
  "IKENNE",
  "REMO-NORTH",
  "OGUN-WATERSIDE",
  "IJEBU-EAST"
];
export const cadre = [
  "Master II",
  "Master I",
  "Senior Master",
  "Principal Grd.III",
  "Principal Grd.II",
  "Principal Grd.I",
  "Principal Special Grd.II",
  "Principal Special Grd.I",
  "Principal Special Grd.I",
  "Master III",
  "Senior Master III",
  "Senior Master II",
  "Senior Master I",
  "Senior Master Special",
  "Technical Instructor",
  "Higher Tech.Instructor",
  "Senior Tech.Instructor",
  "Principal Tech.Instructor II",
  "Principal Tech.Instructor I",
  "Assistant Chief Tech.Instructor",
  "Chief Tech.Instructor",
  "Assistant Executive Officer",
  "Executive Officer",
  "Higher Executive Officer",
  "Senior Executive Officer",
  "Principal Executive Officer Grd.II",
  "Principal Executive Officer Grd.I",
  "Assistant Chief Executive Officer",
  "Chief Executive Officer",
  "Secretarial Assistant IV",
  "Secretarial Assistant III",
  "Secretarial Assistant II",
  "Secretarial Assistant I",
  "Senior Secretarial Assistant II",
  "Senior Secretarial Assistant I",
  "Principal Secretariat Assistant",
  "Assistant Chief Secretariat Assistant",
  "Chief Secretariat Assistant",
  "Motor Driver",
  "Heavy Lorry Driver",
  "Motor Driver/Mechanic",
  "Senior Motor Driver/Mechanic Grade II",
  "Senior Motor Driver/Mechanic Grade I",
  "Chief Motor Driver/Mechanic",
  "Clerical Assistant",
  "Clerical Officer Grade II",
  "Clerical Officer Grade I",
  "Senior Clerical Officer",
  "Chief Clerical Officer",
  "Store Assistant",
  "Storekeeper",
  "Senior Storekeeper",
  "Assistant Chief Storekeeper",
  "Chief Storekeeper",
  "Messenger",
  "Senior Messenger",
  "Head Messenger",
  "Watchman Grade II",
  "Watchman Grade I",
  "Senior Watchman",
  "Head Watchman",
  "Cleaner",
  "Senior Cleaner",
  "Head Cleaner"
];
export const executiveOfficer = [
  "Assistant Executive Officer",
  "Executive Officer",
  "Higher Executive Officer",
  "Senior Executive Officer",
  "Principal Executive Officer Grd.II",
  "Principal Executive Officer Grd.I",
  "Assistant Chief Executive Officer",
  "Chief Executive Officer",
]

export const secreteriatAssistant = [
  "Secretarial Assistant IV",
  "Secretarial Assistant III",
  "Secretarial Assistant II",
  "Secretarial Assistant I",
  "Senior Secretarial Assistant II",
  "Senior Secretarial Assistant I",
  "Principal Secretariat Assistant",
 ]
 export const chiefSecretariat = ["Chief Secretariat Assistant"]
  export const assistantChiefSecretariat = ["Assistant Chief Secretariat Assistant"]

export const driverStorekeeperClerical = [
  "Motor Driver/Mechanic",
  "Senior Motor Driver/Mechanic Grade II",
  "Senior Motor Driver/Mechanic Grade I",
  "Chief Motor Driver/Mechanic",
  "Clerical Assistant",
  "Clerical Officer Grade II",
  "Clerical Officer Grade I",
  "Senior Clerical Officer",
  "Chief Clerical Officer",
  "Store Assistant",
  "Storekeeper",
  "Senior Storekeeper",
  "Assistant Chief Storekeeper",
  "Chief Storekeeper",
]

export const messengerAndWatchman = [
  "Messenger",
  "Senior Messenger",
  "Head Messenger",
  "Watchman Grade II",
  "Watchman Grade I",
  "Senior Watchman",
  "Head Watchman",
]

export const cleaner = [
  "Cleaner",
  "Senior Cleaner",
  "Head Cleaner"
]

export const graduateCadre = [
  "Master II",
  "Master I",
  "Senior Master",
  "Principal Grd.III",
  "Principal Grd.II",
  "Principal Grd.I",
  "Principal Special Grd.II",
  "Principal Special Grd.I",
  "Principal Special Grd.I"
];

export const nceCadre = [
  "Master III",
  "Master II",
  "Master I",
  "Senior Master III",
  "Senior Master II",
  "Senior Master I",
  "Senior Master Special",
]